import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
  IconButton,
} from '@mui/material';
import {useStyles} from './styles';
import {Close, Search} from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';
import {sortFilters} from '@app/helper/plan-and-meet';

const itemsToAdd = 100;
export interface IProps {
  data: IFilterHQ[];
  open: boolean;
  parentId: number;
  onClose: () => void;
  onApplyFilter: (data: any) => void;
}
export interface IFilterHQ {
  id: number;
  value: string;
  count: number;
  parentId: number;
  isDefault: boolean;
  isChecked: boolean;
}
const FilterSearchDailog = (props: IProps) => {
  const [checked, setChecked] = React.useState<IFilterHQ[]>([]);
  const [filteredArray, setFilteredArray] = React.useState<IFilterHQ[]>([]);
  const [tempArray, setTempArray] = React.useState<IFilterHQ[]>([]);
  const [itemsToShow, setItemToShow] = React.useState<number>(itemsToAdd);
  const [searchText, setSearchText] = React.useState<string>('');
  const [regionId, setRegionId] = React.useState<number>(0);
  const {data} = props;

  const classes = useStyles();

  React.useEffect(() => {
    if (data?.length) {
      const sortedArray = sortCheckedValues([...data]);
      setTempArray(sortedArray);
      const defaultChecked = sortedArray?.filter(filterItem => {
        return filterItem.isChecked;
      });
      setChecked(defaultChecked);
      const newSortedArray = [...sortedArray]?.splice(0, itemsToShow);
      setFilteredArray(newSortedArray);
      setRegionId(props.parentId);
    }
  }, [data]);

  const handleToggle = (value: IFilterHQ) => () => {
    const currentIndex = checked?.findIndex(obj => obj?.id === value?.id);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked?.push(value);
    } else {
      newChecked?.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    const newArray = tempArray?.map(item => {
      return item.id === value.id
        ? {...item, isChecked: !item.isChecked}
        : item;
    });
    setTempArray(newArray);
  };

  const onchange = (event: any) => {
    const {value} = event?.target;
    setSearchText(value);
    searchHQ(value, itemsToAdd);
  };
  const searchHQ = (searchValue: string, limit: number) => {
    if (searchValue?.length > 0) {
      const filtered = tempArray
        ?.filter(entry =>
          entry?.value.toLowerCase().includes(searchValue.toLowerCase()),
        )
        .sort((a, b) => {
          return sortTwoValues(a, b, searchValue);
        });
      const reFiltered = sortCheckedValues(filtered);
      setFilteredArray(reFiltered.splice(0, limit));
    } else {
      setFilteredArray([...tempArray].splice(0, limit));
    }
    setItemToShow(limit);
  };
  const sortCheckedValues = (array: IFilterHQ[]) => {
    return [...array]?.sort((prev: IFilterHQ, next: IFilterHQ) => {
      if (prev?.isChecked === next?.isChecked) {
        return 0;
      }
      return prev?.isChecked ? -1 : 1;
    });
  };

  const sortTwoValues = (
    firstValue: IFilterHQ,
    secondValue: IFilterHQ,
    search: string,
  ) => {
    const aStarts = firstValue?.value
      .toLowerCase()
      .startsWith(search.toLowerCase());
    const bStarts = secondValue?.value
      .toLowerCase()
      .startsWith(search.toLowerCase());
    if (aStarts && bStarts)
      return firstValue?.value.localeCompare(secondValue?.value);
    if (aStarts && !bStarts) return -1;
    if (!aStarts && bStarts) return 1;
    return firstValue?.value.localeCompare(secondValue?.value);
  };
  const onClearClick = () => {
    const newArray = tempArray?.map(item => {
      return {...item, isChecked: false};
    });
    const clearSortedArray = sortFilters(newArray);
    setTempArray(clearSortedArray);
    setFilteredArray([...clearSortedArray]?.splice(0, itemsToShow));
    setChecked([]);
    setSearchText('');
  };
  const onApplyFilter = () => {
    const finalArray = sortFilters(tempArray);
    const sortedArray = sortCheckedValues(finalArray);
    props.onApplyFilter({sortedArray, regionId});
  };

  const fetchMoreData = () => {
    searchHQ(searchText, itemsToShow + itemsToAdd);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      classes={{paper: classes.dialog}}
      scroll="paper">
      <Box className={classes.boxtitle}>
        <DialogTitle className={classes.title}>Select HQs</DialogTitle>
        <IconButton
          color="primary"
          aria-label="filter close"
          component="span"
          id="filter_close"
          onClick={props.onClose}
          data-testid={'filter_close'}>
          <Close />
        </IconButton>
      </Box>
      <TextField
        inputProps={{'data-testid': 'input-search'}}
        className={classes.input}
        placeholder="Search"
        onChange={onchange}
        value={searchText}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <DialogContent
        sx={{padding: 0, overflow: 'auto', height: '50px'}}
        id={'scrollableDiv'}>
        <List sx={{width: '100%', bgcolor: 'background.paper'}}>
          <InfiniteScroll
            dataLength={itemsToShow}
            style={{overflow: 'hidden'}}
            next={fetchMoreData}
            hasMore={tempArray?.length > filteredArray?.length}
            loader={''}
            scrollableTarget="scrollableDiv">
            {filteredArray?.map(element => {
              const labelId = `checkbox-list-label-${element.id}`;
              return (
                <ListItem key={element.id} className={classes.listItem}>
                  <ListItemButton
                    data-testid={labelId}
                    className={classes.listItemButton}
                    role={undefined}
                    onClick={handleToggle(element)}
                    dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={
                          checked.findIndex(obj => obj.id === element.id) !== -1
                        }
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-labelledby': labelId}}
                        className={classes.checkbox}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`${element.value} (${element.count})`}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </InfiniteScroll>
        </List>
      </DialogContent>
      <DialogActions>
        <Box className={classes.box}>
          <Button
            data-testid="button-clear"
            variant="contained"
            className={classes.paperButton}
            onClick={onClearClick}>
            Clear
          </Button>
          <Button
            data-testid="button-apply"
            variant="contained"
            className={classes.applyButton}
            onClick={onApplyFilter}>
            Apply
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default FilterSearchDailog;
