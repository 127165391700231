import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {SearchBar} from '@app/components/widgets/searchBar';
import {useStyles} from './styles';
import {Label} from '@app/components/elements/Label';
import { gspScheduleSelector, gspScheduleStateActions } from '@app/screens/configuration-management/pages/gsp-schedule/redux';

export const GspScheduleNavbar = () => {
  const dispatch = useDispatch();
  const [searchError, setSearchError] = useState(false);

  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  const classes = useStyles();
  const searchText = useSelector(
    gspScheduleSelector.getGspScheduleSearchText(),
  );
  const gridOptions = useSelector(
    gspScheduleSelector.getGspScheduleGridOptions(),
  );

  const gspScheduleList = useSelector(
    gspScheduleSelector.getCloneGspScheduleList(),
  );

  const fetchListData = () => {
    dispatch(
      gspScheduleStateActions.setGspScheduleGridOptions({
        ...gridOptions,
        pageNo: 0,
      }),
    );

    const filteredData = gspScheduleList?.filter(
      (item: any) =>
        item?.divisionName
          ?.toLowerCase()
          .includes(searchText?.toLowerCase())
    );

    dispatch(
      gspScheduleStateActions.setGspScheduleList(filteredData),
    );
  };

  const handleSearchTextChange = (value: any) => {
    dispatch(gspScheduleStateActions.setGspScheduleSearchText(value));
    if (!value?.length || value?.length >= 3) {
      setSearchError(false);
    }
  };

  const handleSearchPress = (e: any) => {
    if (e.key === 'Enter') {
      if (searchText?.length >= 3 || searchText?.length === 0) {
        setSearchError(false);
        fetchListData();
      } else {
        setSearchError(true);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        <Label
          title={navbarComponentHeading}
          variant="h6"
          testID={'superman_admin_navbar_heading'}
          textColor={'#322b7c'}
          fontWeight={'800'}
          hideToolTip
        />
      </div>
      <div className={classes.searchContainer}>
        <SearchBar
          searchText={searchText}
          handleChange={handleSearchTextChange}
          handleSearch={handleSearchPress}
          showError={searchError}
          placeHolder={'Search here'}
          errorMessage={'Please Enter Minimum 3 Characters'}
        />
      </div>
    </div>
  );
};
