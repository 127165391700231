import * as React from 'react';
import {Typography} from '@mui/material';
import theme from '@app/themes';
import Tooltip from '@mui/material/Tooltip';

/** typography types */
export const LabelVariant = Object.entries(theme.typography).reduce(
  (acc, item) => Object.assign(acc, {[item[0]]: item[0]}),
  {},
);

/**
 * Label component using Text from react-native.
 * This serves the purpose to make the use of text consistent throughtout the app
 * @param {String} title title of text
 * @param {Number} size size of text
 * @param {String} type font type of the text ,  can be regular|bold|semiBold,default is regular
 * @param {String} testID date test id
 * @param {Object} style custom style of text
 */

//required props
interface LabelRequiredProps {
  title: any;
}

//options props
interface LabelOptionalProps {
  testID: any;
  classes: any;
  size: number;
  textColor: string;
  type: any;
  variant: any;
  hideToolTip: boolean;
  component: any;
  fontWeight: any;
  tooltipTitle?: string;
}

// Use the optional prop interface to define the default props
const defaultLabelProps: LabelOptionalProps = {
  textColor: theme.colors.grey[200],
  size: 12,
  type: 'regular',
  testID: 'text_test',
  variant: 'body',
  classes: {},
  hideToolTip: false,
  component: 'div',
  fontWeight: 500,
};

interface LabelProps extends LabelRequiredProps, LabelOptionalProps {}

export const Label = (props: LabelProps) => {
  const {
    title,
    testID,
    classes,
    size,
    textColor,
    type,
    variant,
    hideToolTip,
    component,
    fontWeight,
    tooltipTitle,
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(!hideToolTip);
  };
  return (
    <Tooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      arrow
      PopperProps={{disablePortal: true}}
      title={tooltipTitle || title}>
      <Typography
        variant={variant}
        fontSize={size}
        noWrap
        component={component}
        color={textColor}
        fontWeight={fontWeight}
        data-testid={testID}
        className={classes}
        fontStyle={getFontFamily(type)}>
        {title}
      </Typography>
    </Tooltip>
  );
};

const getFontFamily = (type: any) => {
  switch (type) {
    case 'bold':
      return theme.fonts.fontBold;
    case 'regular':
      return theme.fonts.fontRegular;
    case 'semiBold':
      return theme.fonts.fontSemiBold;
    case 'medium':
      return theme.fonts.fontMedium;
    case 'light':
      return theme.fonts.fontLight;
    default:
      return theme.fonts.fontRegular;
  }
};

Label.defaultProps = defaultLabelProps;
