import {createAction, createSlice} from '@reduxjs/toolkit';

export const userManagementInitialState = {
  isActiveState: true,
  userSearchText: '',
  divisionDropDown: [],
  stateDropDown: [],
  accessGroupDropDown: [],
  resetNavbarVersion: 0,

  adminUsers: [],
  adminUsersSnaShot: [],
  adminUsersLoading: false,
  adminUsersError: false,
  adminUsersSuccess: false,
  adminUsersCount: 0,
  adminUsersErrorMessage: '',

  adminUsersAutosuggestSearchText: '',
  adminUsersAutosuggestData: [],
  adminUsersAutosuggestLoading: false,
  adminUsersAutosuggestError: false,
  adminUsersAutosuggestSuccess: false,
  adminUsersAutosuggestErrorMessage: '',
  selectedAdminUserInAutoSuggest: {},

  adminAddUsersAutosuggestData: [],
  adminAddUsersAutosuggestLoading: false,
  adminAddUsersAutosuggestError: false,
  adminAddUsersAutosuggestSuccess: false,
  adminAddUsersAutosuggestErrorMessage: '',
  selectedAdminAddUserInAutoSuggest: {},

  editUserSuccess: false,
  editUserError: false,
  editUserErrorMessage: '',
  editUserLoading: false,

  addUserSuccess: false,
  addUserError: false,
  addUserErrorMessage: '',
  addUserLoading: false,

  disableUserSuccess: false,
  disableUserError: false,
  disableUserErrorMessage: '',
  disableUserLoading: false,

  editUserPopupVisible: false,
  editUserValue: {},

  addUserPopupVisible: false,
  addUserValue: {},
};

export const fetchAdminUsersCreator = createAction<any>(
  'USER_MANAGEMENT_HANDLER/FETCH_ADMIN_USERS',
);
export const fetchAdminUsersCreatorTypeName = fetchAdminUsersCreator(null).type;

export const fetchAdminUsersAutoSuggestCreator = createAction(
  'USER_MANAGEMENT_HANDLER/FETCH_ADMIN_USERS_AUTO_SUGGEST',
);
export const fetchAdminUsersAutoSuggestCreatorTypeName =
  fetchAdminUsersAutoSuggestCreator().type;

export const fetchAdminAddUsersAutoSuggestCreator = createAction(
  'USER_MANAGEMENT_HANDLER/FETCH_ADMIN_ADD_USERS_AUTO_SUGGEST',
);
export const fetchAdminAddUsersAutoSuggestCreatorTypeName =
  fetchAdminAddUsersAutoSuggestCreator().type;

export const fetchStateDropdownCreator = createAction(
  'USER_MANAGEMENT_HANDLER/FETCH_STATE_DROPDOWN',
);
export const fetchStateDropdownCreatorTypeName =
  fetchStateDropdownCreator().type;

export const fetchDivisionDropdownCreator = createAction(
  'USER_MANAGEMENT_HANDLER/FETCH_DIVISION_DROPDOWN',
);
export const fetchDivisionDropdownCreatorTypeName =
  fetchDivisionDropdownCreator().type;

export const fetchAccessGroupDropDownCreator = createAction(
  'USER_MANAGEMENT_HANDLER/FETCH_ACCESS_GROUP_DROPDOWN',
);

export const fetchAccessGroupDropDownCreatorTypeName =
  fetchAccessGroupDropDownCreator().type;

export const addAdminUserCreator = createAction<any>(
  'USER_MANAGEMENT_HANDLER/ADD_ADMIN_USER',
);

export const addAdminUserCreatorTypeName = addAdminUserCreator({}).type;

export const disableAdminUserCreator = createAction<any>(
  'USER_MANAGEMENT_HANDLER/DISABLE_ADMIN_USER',
);

export const disableAdminUserCreatorTypeName = disableAdminUserCreator({}).type;

export const editAdminUserCreator = createAction<any>(
  'USER_MANAGEMENT_HANDLER/EDIT_ADMIN_USER',
);

export const editAdminUserCreatorTypeName = editAdminUserCreator({}).type;

const userManagementStateHandler = createSlice({
  name: 'USER_MANAGEMENT_HANDLER',
  initialState: userManagementInitialState,
  reducers: {
    setAdminUsers: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminUsers: payload,
      };
    },
    setAdminUsersLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminUsersLoading: payload,
      };
    },
    setAdminUsersError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminUsersError: payload,
      };
    },
    setAdminUsersSuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminUsersSuccess: payload,
      };
    },
    setAdminUsersCount: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminUsersCount: payload,
      };
    },
    setAdminUsersErrorMessage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminUsersErrorMessage: payload,
      };
    },
    clearAdminUsersError: prevState => {
      return {
        ...prevState,
        adminUsersError: false,
        adminUsersErrorMessage: '',
      };
    },
    setIsActiveState: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isActiveState: payload,
      };
    },
    setUserSearchText: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        userSearchText: payload,
      };
    },
    setDivisionDropDown: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        divisionDropDown: payload,
      };
    },
    setStateDropDown: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        stateDropDown: payload,
      };
    },
    setAccessGroupDropDown: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        accessGroupDropDown: payload,
      };
    },
    setEditUserSuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editUserSuccess: payload,
      };
    },
    setEditUserError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editUserError: payload,
      };
    },
    setEditUserErrorMessage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editUserErrorMessage: payload,
      };
    },
    setEditUserLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editUserLoading: payload,
      };
    },
    clearEditUserError: prevState => {
      return {
        ...prevState,
        editUserError: false,
        editUserErrorMessage: '',
      };
    },
    setAddUserSuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addUserSuccess: payload,
      };
    },
    setAddUserError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addUserError: payload,
      };
    },
    setAddUserErrorMessage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addUserErrorMessage: payload,
      };
    },
    setAddUserLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addUserLoading: payload,
      };
    },
    clearAddUserError: prevState => {
      return {
        ...prevState,
        addUserError: false,
        addUserErrorMessage: '',
      };
    },
    setDisableUserSuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        disableUserSuccess: payload,
      };
    },
    setDisableUserError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        disableUserError: payload,
      };
    },
    setDisableUserErrorMessage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        disableUserErrorMessage: payload,
      };
    },
    setDisableUserLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        disableUserLoading: payload,
      };
    },
    clearDisableUserError: prevState => {
      return {
        ...prevState,
        disableUserError: false,
        disableUserErrorMessage: '',
      };
    },
    setEditUserPopupVisible: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editUserPopupVisible: payload,
      };
    },
    setEditUserValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editUserValue: payload,
      };
    },
    setAddUserPopupVisible: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addUserPopupVisible: payload,
      };
    },
    setAddUserValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addUserValue: payload,
      };
    },
    setAdminUsersAutosuggestData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminUsersAutosuggestData: payload,
      };
    },
    setAdminUsersAutosuggestLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminUsersAutosuggestLoading: payload,
      };
    },
    setAdminUsersAutosuggestError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminUsersAutosuggestError: payload,
      };
    },
    setAdminUsersAutosuggestErrorMessage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminUsersAutosuggestErrorMessage: payload,
      };
    },
    clearAdminUsersAutosuggestError: prevState => {
      return {
        ...prevState,
        adminUsersAutosuggestError: false,
        adminUsersAutosuggestErrorMessage: '',
      };
    },
    setSelectedAdminUserInAutoSuggest: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        selectedAdminUserInAutoSuggest: payload,
      };
    },

    setAdminAddUsersAutosuggestData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminAddUsersAutosuggestData: payload,
      };
    },
    setAdminAddUsersAutosuggestLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminAddUsersAutosuggestLoading: payload,
      };
    },
    setAdminAddUsersAutosuggestError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminAddUsersAutosuggestError: payload,
      };
    },
    setAdminAddUsersAutosuggestErrorMessage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminAddUsersAutosuggestErrorMessage: payload,
      };
    },
    clearAdminAddUsersAutosuggestError: prevState => {
      return {
        ...prevState,
        adminAddUsersAutosuggestError: false,
        adminAddUsersAutosuggestErrorMessage: '',
      };
    },
    setSelectedAdminAddUserInAutoSuggest: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        selectedAdminAddUserInAutoSuggest: payload,
      };
    },
    setAdminUsersAutosuggestSearchText: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminUsersAutosuggestSearchText: payload,
      };
    },

    setAdminUsersSnaShot: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        adminUsersSnaShot: payload,
      };
    },

    setResetNavbarVersion: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        resetNavbarVersion: payload,
      };
    },
  },
});

export const userManagementStateActions = userManagementStateHandler.actions;
export const userManagementStateReducer = userManagementStateHandler.reducer;
