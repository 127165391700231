import {useEffect, useState} from 'react';
import {Badge, Box, IconButton, Popover} from '@mui/material';
import {NotificationsNone} from '@mui/icons-material';
import theme from '@app/themes';
import {makeStyles} from '@mui/styles';
import {
  getFormatDate,
  getLocalTimeZoneTime,
  getLocalTimeZoneDate,
} from '@app/utils/dateTimeHelper';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchNotificationsCreator,
  markNotificationReadCreator,
} from '@app/store/reducers/appSlice';
import {appSelector} from '@app/store/selectors';
import {Label} from '../elements/Label';

const labelFont = {
  fontSize: 9.3,
  lineHeight: '13.3px',
};

const useStyles = makeStyles({
  notificationContainer: {
    width: '333px',
    minHeight: '95vh',
    borderWidth: 1,
    borderRadius: 13.3,
    backgroundColor: theme.colors.blue[1400],
    color: theme.colors.white,
    paddingBottom: theme.spacing(12),
    overflow: 'hidden',
  },
  notificationClose: {
    color: 'white',
  },
  notificationHeading: {
    marginLeft: theme.spacing(16),
    marginRight: theme.spacing(16),
    paddingTop: theme.spacing(10.7),
  },
  notificationBox: {
    width: '300px',
    height: '150px',
    marginLeft: theme.spacing(16),
    marginTop: theme.spacing(10.7),
    paddingTop: theme.spacing(5),
    backgroundColor: theme.colors.blue[1500],
    boxSizing: 'border-box',
  },
  notificationMessage: {
    paddingLeft: theme.spacing(10.7),
    paddingRight: theme.spacing(10.7),
    paddingTop: theme.spacing(9.3),
    opacity: 0.8,
    whiteSpace: 'normal',
    ...labelFont,
  },
  timeStyle: {
    marginLeft: theme.spacing(10.7),
    paddingTop: theme.spacing(13.3),
    opacity: 0.4,
    ...labelFont,
  },
  dateStyle: {
    marginLeft: theme.spacing(16),
    marginTop: theme.spacing(26.7),
    ...labelFont,
  },
  notificationAlert: {
    color: theme.colors.blue[1500],
  },
  redDot: {
    height: 6.7,
    width: 6.7,
    justifyContent: 'flex-end',
    display: 'flex',
    marginLeft: 290,
    backgroundColor: theme.colors.red[700],
    borderRadius: 50,
  },
});

function NotificationsComponent() {
  const dispatch = useDispatch();
  const notifications = useSelector(appSelector.getNotifications());

  let showOlderTitle = true;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (open) {
      dispatch(fetchNotificationsCreator());
    }
  }, [open]);

  const isCurrentDate = (date: any) => {
    const todayDate = new Date();
    const argDate = date;
    if (todayDate.getDate() === argDate.getDate()) {
      return true;
    } else {
      return false;
    }
  };

  const sameDateAsPrevious = (data: Array<any>, index: number) => {
    const todayDate = new Date();
    const currentDate = getLocalTimeZoneDate(data[index].createdOn);
    if (index !== 0) {
      const previousDateCheck = getLocalTimeZoneDate(data[index - 1].createdOn);
      if (currentDate.getDate() === previousDateCheck.getDate()) {
        return true;
      } else {
        return false;
      }
    } else {
      if (currentDate.getDate() === todayDate.getDate()) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getCurrentDateFormatted = () => {
    return `Today, ${getFormatDate({format: 'DD MMMM YYYY'})}`;
  };

  const getSelectedDateFormatted = (data: any) => {
    return `${getFormatDate({
      date: data,
      format: 'DD MMMM YYYY',
    })}`;
  };

  const updateTitleStatus = () => {
    showOlderTitle = false;
  };
  const showRedDotOnNotificationIcon = () => {
    if (notifications?.length) {
      for (const notification of notifications) {
        if (!notification?.isRead) {
          return true;
        }
      }
    }
    return false;
  };
  const handleVisit = (item: any) => {
    if (!item?.isRead) {
      dispatch(markNotificationReadCreator(item.id));
    }
  };

  const renderNotificationCard = (notification: any) => {
    const notificationMessage = notification?.data?.UnmappedActivity
      ? `${notification.message} "${notification?.data?.UnmappedActivity}" immediately.`
      : notification.message;

    return (
      <Box key={notification.id} className={classes.notificationBox}>
        {!notification?.isRead && <div className={classes.redDot} />}
        <Label
          title={getLocalTimeZoneTime(notification?.createdOn)}
          classes={classes.timeStyle}
          textColor={theme.colors.white}
        />
        <Box>
          <Label
            title={notificationMessage}
            textColor={theme.colors.white}
            classes={classes.notificationMessage}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box textAlign="right" marginTop="10px" marginX="20px">
      {showRedDotOnNotificationIcon() ? (
        <IconButton
          className={classes.notificationAlert}
          aria-describedby={id}
          onClick={handleClick}
          data-testid="notifications_icon">
          <Badge color="error" overlap="circular" variant="dot">
            <NotificationsNone />
          </Badge>
        </IconButton>
      ) : (
        <IconButton
          className={classes.notificationAlert}
          aria-describedby={id}
          onClick={handleClick}
          data-testid="notifications_icon">
          <NotificationsNone />
        </IconButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        data-testid="notifications_popover">
        <Box className={classes.notificationContainer}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.notificationHeading}>
            <Label title="Notifications" textColor={theme.colors.white} />
            <IconButton
              onClick={handleClose}
              className={classes.notificationClose}>
              <NotificationsNone />
            </IconButton>
          </Box>

          {notifications?.length > 0 ? (
            <>
              <Label
                title={getCurrentDateFormatted()}
                textColor={theme.colors.white}
                classes={classes.dateStyle}
              />
              {notifications?.map((notification: any, index: number) => (
                <>
                  {!sameDateAsPrevious(notifications, index) && (
                    <Label
                      title={getSelectedDateFormatted(
                        getLocalTimeZoneDate(notification?.createdOn),
                      )}
                      textColor={theme.colors.white}
                      classes={classes.dateStyle}
                    />
                  )}
                  {showOlderTitle &&
                    !isCurrentDate(
                      getLocalTimeZoneDate(notification?.createdOn),
                    ) &&
                    updateTitleStatus()}
                  <div
                    data-testid={`notifications_card${notification?.id}`}
                    onClick={() => {
                      handleVisit(notification);
                    }}>
                    {renderNotificationCard(notification)}
                  </div>
                </>
              ))}
            </>
          ) : (
            <Label
              title="No Notifications"
              classes={classes.notificationHeading}
              textColor={theme.colors.white}
            />
          )}
        </Box>
      </Popover>
    </Box>
  );
}

export default NotificationsComponent;
