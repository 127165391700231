import {createSelector} from '@reduxjs/toolkit';

export const filterSelector = {
  getFilterState: () => {
    return createSelector(
      [(state: any) => state?.filterState?.filters],
      filters => filters,
    );
  },
  getDefaultFilterResponse: () => {
    return createSelector(
      [(state: any) => state?.filterState?.setDefaultFilterResponse],
      data => data,
    );
  },
  getApplyDisableStatus: () => {
    return createSelector(
      [(state: any) => state?.filterState?.applyDisableStatus],
      data => data,
    );
  },
};
