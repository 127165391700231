import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {rootSaga} from './sagas';
import {rootReducer} from './reducers';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = compose;
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));
const store = createStore(
  rootReducer ||
    (() => {
      return {};
    }),
  enhancer,
);
sagaMiddleware.run(rootSaga);

export const getStore = () => store;
