import {store} from '@app/App';
import {
  ROLES_TABLE,
  USER_ROLE_MR,
  USER_ROLE_FLM,
  USER_ROLE_SLM,
  MTP_STATUS,
  NO_RECORD,
  APPROVED,
  WORK_IN_PROGRESS,
  STPSTATUSKEY,
  MTPSTATUSKEY,
  DIVISIONSKEY,
  ZONESKEY,
  REGIONSKEY,
  DCRSTATUSKEY,
  LOCKUNLOCKSTATUSKEY,
  HEADQUARTERSKEY,
  DCR_STATUS_LABEL,
  APPROVALSTATUSKEY,
  DCRTYPEKEY,
  DCR_TYPE,
  ROLES_RANK,
  USER_APP_SETTINGS_KEY,
} from '@app/common/constants';
import {IPlanAndMeetStatus} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux/slice';
import {getDaysInCurrentMonth} from '@app/utils/dateTimeHelper';

export const capitalize = (input: any) => {
  input = input === undefined || input === null ? '' : input;

  return input
    .toString()
    .toLowerCase()
    .replace(/(^|\.|\s *)([a-z])/g, function (match: any) {
      return match.toUpperCase();
    });
};

export const capitalizeName = (input: any) => {
  input = input === undefined || input === null ? '' : input;

  return input
    .toString()
    .toLowerCase()
    .replace(/(?:\b|_)([a-z])/g, function (match: any) {
      return match.toUpperCase();
    })
    .replace('Vt-', 'VT-');
};

/*get id of roles*/
export const getRoleIdFromRolesList = (roles: any, roleName: any) => {
  const role = roles?.find((item: any) => item?.shortName === roleName);
  return role ? role?.id : 0;
};

/*get id of roles*/
export const getRoleId = (role: any) => {
  const roleId = Object.keys(ROLES_TABLE).find(
    key => ROLES_TABLE[key as keyof object] === role,
  );
  return Number(roleId || 0);
};
/*Check if user's role is MR*/
export const isUserRoleMR = (role: number) => {
  return role === getRoleId(USER_ROLE_MR);
};
export const isUserRoleFLM = (role: number) => {
  return role === getRoleId(USER_ROLE_FLM);
};
export const isUserRoleSLM = (role: number) => {
  return role === getRoleId(USER_ROLE_SLM);
};

/*Check if user's MTP status is no records*/
export const isMTPNoRecordsOrWorkInProgress = (mtpStatus: number) => {
  const noRecordStatus = Number(
    Object.keys(MTP_STATUS).find(
      key => MTP_STATUS[key as keyof object] === NO_RECORD,
    ),
  );
  const workInProgressStatus = Number(
    Object.keys(MTP_STATUS).find(
      key => MTP_STATUS[key as keyof object] === WORK_IN_PROGRESS,
    ),
  );
  return mtpStatus === noRecordStatus || mtpStatus === workInProgressStatus;
};

export const isPastEndDate = (
  rowData: IPlanAndMeetStatus,
  appSettingsData: any,
) => {
  const currentDate = new Date();
  const currentFormattedDate = currentDate.getDate();
  let formattedSubmitDate = 0;
  let formattedStartDay = 0;
  const isUserMR = isUserRoleMR(rowData?.roleId);
  const isUserFLM = isUserRoleFLM(rowData?.roleId);
  const isUserSLM = isUserRoleSLM(rowData?.roleId);
  if (isUserMR) {
    const gracePeriod = parseInt(findGracePeriodForMR(appSettingsData));
    formattedSubmitDate = formattedCurrentDay(appSettingsData, 'MTPSUBMIT');
    formattedSubmitDate = formattedSubmitDate + gracePeriod;
  } else if (isUserFLM) {
    formattedSubmitDate = formattedCurrentDay(appSettingsData, 'FLMMTPSUBMIT');
    formattedStartDay = formatStartDay(appSettingsData, 'FLMMTPSUBMIT');
  } else if (isUserSLM) {
    formattedSubmitDate = formattedCurrentDay(appSettingsData, 'SLMMTPSUBMIT');
    formattedStartDay = formatStartDay(appSettingsData, 'SLMMTPSUBMIT');

  }
  if (formattedSubmitDate <= getDaysInCurrentMonth()) {
    if(isUserFLM || isUserSLM && formattedStartDay > formattedSubmitDate){        
      formattedSubmitDate = getDaysInCurrentMonth() + formattedSubmitDate        
    }
    return currentFormattedDate > formattedSubmitDate;
  } else {
    return false;
  }
};

/*Check if user's MTP status is approved*/
export const isMTPApproved = (mtpStatus: number) => {
  const approvedStatus = Number(
    Object.keys(MTP_STATUS).find(
      key => MTP_STATUS[key as keyof object] === APPROVED,
    ),
  );
  return mtpStatus === approvedStatus;
};

export const getPlanAndMeetObject = (state: any) => {
  return {
    roleIds:
      !state?.roles?.length ||
      (state?.roles?.length === 1 && state?.roles?.indexOf(0) !== -1) ||
      !state?.roles?.length
        ? null
        : state?.roles,
    pageNo: state?.gridOptions?.pageNo + 1 || 1,
    pageSize: state?.gridOptions?.pageSize || 10,
    isCurrentMonth: (state?.monthSelected as typeof Boolean)
      ? !state?.monthSelected
      : true,
    sortColumn: state?.gridOptions?.sortColumn || 'mtpStatus',
    searchValue: state?.searchText,
    sortType: state?.gridOptions?.sortType || 'asc',
    filters: getAppliedFilter(state?.filters) || {},
  };
};

const getAppliedFilter = (filters: any) => {
  let updatedFilters = JSON.parse(JSON.stringify(filters ? filters : {}));

  updatedFilters[HEADQUARTERSKEY] = updatedFilters?.[HEADQUARTERSKEY]?.map(
    (hq: any) => hq.id,
  );

  const filterState: any = store?.getState()?.filterState?.filters;
  const preferredFilters: any =
    store?.getState()?.planAndMeetStatusMaster?.preferredFilters;

  const mtpStatus = Object.keys(filterState)?.length
    ? filterState?.mtpStatus?.filterData
    : preferredFilters?.mtpStatus;

  updatedFilters[MTPSTATUSKEY] = updatedFilters?.[MTPSTATUSKEY]?.map(
    (mtpStatusId: any) =>
      mtpStatus?.find((item: any) => item?.id === mtpStatusId),
  )?.filter((mtpStatus: any) => mtpStatus);
  return updatedFilters;
};

export const downloadExcel = (
  state: any,
  filters: any,
  appliedFilters: any,
) => {
  return {
    roleIds:
      !state?.roles?.length ||
      (state?.roles?.length === 1 && state?.roles?.indexOf(0) !== -1) ||
      !state?.roles?.length
        ? null
        : state?.roles,
    pageNo: state?.gridOptions?.pageNo + 1 || 1,
    pageSize: state?.gridOptions?.pageSize || 10,
    isCurrentMonth: (state?.monthSelected as typeof Boolean)
      ? !state?.monthSelected
      : true,
    sortColumn: state?.gridOptions?.sortColumn || 'mtpStatus',
    searchValue: state?.searchText,
    sortType: state?.gridOptions?.sortType || 'asc',
    filters: createDownloadExcelFilters(filters, appliedFilters) || {},
  };
};

export const getStpEditObject = (
  state: any,
  rowData: any,
  isDCREnable: boolean = false,
  IsSTPDCREnable?: boolean,
) => {
  return {
    staffPositionId: rowData.staffPositionId,
    isCurrentMonth: (state?.monthSelected as typeof Boolean)
      ? !state?.monthSelected
      : true,
    isDCREnable,
    IsSTPDCREnable,
  };
};

export const getMtpEditObject = (
  state: any,
  rowData: any,
  status: string,
  isDCREnable: boolean = false,
) => {
  return {
    staffPositionId: rowData.staffPositionId,
    isCurrentMonth: (state?.monthSelected as typeof Boolean)
      ? !state?.monthSelected
      : true,
    status: Number(
      Object.keys(MTP_STATUS).find(
        key => MTP_STATUS[key as keyof object] === status,
      ),
    ),
    isDCREnable,
  };
};

export const getMonthsArray = () => {
  const currentDate = new Date();
  let nextMonth;
  if (currentDate.getMonth() == 11) {
    nextMonth = new Date(currentDate.getFullYear() + 1, 0, 1);
  } else {
    nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1,
    );
  }
  const currentMonthName = currentDate.toLocaleString('default', {
    month: 'short',
  });
  const nextMonthName = nextMonth.toLocaleString('default', {month: 'short'});
  return [currentMonthName, nextMonthName];
};

export const transformFilters = (filters: any, appliedFilters?: any) => {
  Object.keys(filters)?.forEach((filterKey: string) => {
    if (filterKey === DCRSTATUSKEY || filterKey === LOCKUNLOCKSTATUSKEY) {
      filters[filterKey]['filterData'] = filters[filterKey]?.filterData?.map(
        (item: any, index: number) => ({
          ...item,
          isChecked: appliedFilters?.[filterKey]?.[index],
        }),
      );
    } else if (
      filterKey === STPSTATUSKEY ||
      filterKey === MTPSTATUSKEY ||
      filterKey === DIVISIONSKEY ||
      filterKey === ZONESKEY ||
      filterKey === REGIONSKEY ||
      filterKey === APPROVALSTATUSKEY ||
      filterKey === DCRTYPEKEY
    ) {
      filters[filterKey]['filterData'] = filters[filterKey]?.filterData?.map(
        (item: any) => ({
          ...item,
          isChecked: appliedFilters?.[filterKey]?.includes(item?.id),
          value:
            filterKey === DCRTYPEKEY
              ? DCR_TYPE[item?.id as keyof object]
              : item?.value,
        }),
      );
    }
  });

  Object.keys(filters)?.forEach((key: string) => {
    if (filters?.[key]) {
      filters[key]['collapsed'] = true;

      const notChecked =
        filters[key]?.filterData?.length > 0
          ? filters[key]?.filterData?.find((child: any) => !child?.isChecked)
          : true;
      filters[key]['isChecked'] = notChecked ? false : true;
    }
  });

  if (filters?.divisions) {
    filters[DIVISIONSKEY]['filterData'] = sortFilters(filters?.divisions?.filterData);
  }

  // attaching Regions with Zones

  const allRegions = sortFilters(filters?.regions?.filterData) || [];

  if (filters?.zones) {
    filters[ZONESKEY]['filterData'] = sortFilters(filters?.zones?.filterData);

    filters[ZONESKEY]['filterData'] = filters?.zones?.filterData?.map(
      (zone: any) => ({
        ...zone,
        collapsed: true,
        regions: allRegions
          ?.filter((region: any) => region.parentId === zone.id)
          .map((region: any) => ({
            ...region,
            collapsed: true,
            isChecked: zone?.isChecked ? true : region?.isChecked,
          })),
      }),
    );

    const isParentZoneChecked =
      filters?.zones?.filterData?.length > 0
        ? filters?.zones?.filterData?.find((zone: any) => !zone?.isChecked)
        : true;
    filters[ZONESKEY]['isChecked'] = isParentZoneChecked ? false : true;
  }

  return filters;
};

export const attachHQstoRegions = (
  filters: any,
  regionId: number,
  zoneId: number,
  hqs: any,
  appliedFilters?: any,
) => {
  let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));

  hqs = hqs?.map((item: any) => ({
    ...item,
    parentId: regionId,
    zoneId,
    isChecked: appliedFilters?.[HEADQUARTERSKEY]?.find(
      (hq: any) => hq.id === item.id,
    )
      ? true
      : false,
  }));

  hqs = sortFilters(hqs);

  filtersCopy[ZONESKEY]['filterData'] = filtersCopy?.zones?.filterData?.map(
    (zone: any) => {
      if (zone.id === zoneId) {
        zone[REGIONSKEY] = zone?.regions?.map((region: any) => {
          if (region.id === regionId) {
            if (region?.isChecked) {
              hqs = hqs?.map((hq: any) => ({...hq, isChecked: true}));
            }
            return {
              ...region,
              headquarters: hqs,
            };
          }
          return region;
        });
      }
      return zone;
    },
  );

  return filtersCopy;
};

export const createFilterObject = (filters: any, selectedFilters: any) => {
  let appliedFilters: any = {};

  Object.keys(filters).forEach((filterKey: string) => {
    if (filterKey === DCRSTATUSKEY || filterKey === LOCKUNLOCKSTATUSKEY) {
      const res = filters[filterKey]?.filterData?.map((item: any) =>
        Boolean(item.isChecked),
      );
      appliedFilters[filterKey] = res?.length ? res : null;
    } else if (
      filterKey === STPSTATUSKEY ||
      filterKey === MTPSTATUSKEY ||
      filterKey === DIVISIONSKEY ||
      filterKey === ZONESKEY
    ) {
      const res = filters[filterKey]?.filterData
        ?.filter((item: any) => item?.isChecked)
        ?.map((item: any) => item.id);
      appliedFilters[filterKey] = res?.length ? res : null;
    }
  });

  const regions: any = [];
  const hqs: any = [];

  filters[ZONESKEY]?.filterData?.forEach((zone: any) => {
    if (!zone?.isChecked) {
      zone?.regions?.forEach((region: any) => {
        if (region?.isChecked) {
          regions.push(region.id);
        } else {
          region?.headquarters?.forEach((hq: any) => {
            if (hq?.isChecked) {
              hqs.push(hq);
            }
          });
        }
      });
    }
  });

  appliedFilters[REGIONSKEY] = regions?.length ? regions : null;
  appliedFilters[HEADQUARTERSKEY] = hqs?.length ? hqs : null;

  if (selectedFilters?.[HEADQUARTERSKEY]?.length) {
    selectedFilters?.[HEADQUARTERSKEY]?.forEach((hq: any) => {
      if (
        !appliedFilters[REGIONSKEY]?.includes(hq?.parentId) &&
        !appliedFilters[ZONESKEY]?.includes(hq?.zoneId)
      ) {
        if (appliedFilters?.[HEADQUARTERSKEY]?.length) {
          const alreadyExists = appliedFilters?.[HEADQUARTERSKEY]?.find(
            (item: any) => item.id === hq.id,
          );

          if (!alreadyExists) {
            appliedFilters[HEADQUARTERSKEY]?.push(hq);
          }
        } else {
          appliedFilters[HEADQUARTERSKEY] = [
            ...selectedFilters[HEADQUARTERSKEY],
          ];
        }
      }
    });
  }

  return appliedFilters;
};

export const createDownloadExcelFilters = (
  filters: any,
  selectedFilters: any,
) => {
  let appliedFilters: any = {};

  Object.keys(filters).forEach((filterKey: string) => {
    if (filterKey === DCRSTATUSKEY || filterKey === LOCKUNLOCKSTATUSKEY) {
      const res = filters[filterKey]?.filterData
        ?.filter((item: any) => item?.isChecked)
        ?.map((item: any) => Boolean(item.id));
      appliedFilters[filterKey] = res?.length === 1 ? res : null;
    } else if (filterKey === DIVISIONSKEY || filterKey === ZONESKEY) {
      const res = filters[filterKey]?.filterData
        ?.filter((item: any) => item?.isChecked)
        ?.map((item: any) => item);
      appliedFilters[filterKey] = res?.length ? res : null;
    } else if (filterKey === STPSTATUSKEY || filterKey === MTPSTATUSKEY) {
      const res = filters[filterKey]?.filterData
        ?.filter((item: any) => item?.isChecked)
        ?.map((item: any) => item.id);
      appliedFilters[filterKey] = res?.length ? res : null;

      if (filterKey === MTPSTATUSKEY) {
        const filterState: any = store?.getState()?.filterState?.filters;
        appliedFilters[MTPSTATUSKEY] = appliedFilters?.[MTPSTATUSKEY]?.map(
          (mtpStatusId: any) =>
            filterState?.mtpStatus?.filterData?.find(
              (item: any) => item?.id === mtpStatusId,
            ),
        );
      }
    }
  });

  const regions: any = [];
  const hqs: any = [];

  filters[ZONESKEY]?.filterData?.forEach((item: any) => {
    if (!item?.isChecked) {
      item?.regions?.forEach((region: any) => {
        if (region?.isChecked) {
          regions.push(region);
        } else {
          region?.headquarters?.forEach((hq: any) => {
            hq?.isChecked && hqs.push({...hq, parentId: region.id});
          });
        }
      });
    }
  });

  appliedFilters[REGIONSKEY] = regions?.length ? regions : null;
  appliedFilters[HEADQUARTERSKEY] = hqs?.length ? hqs : null;
  if (selectedFilters?.[HEADQUARTERSKEY]?.length) {
    selectedFilters?.[HEADQUARTERSKEY]?.forEach((selectedHq: any) => {
      if (
        !(appliedFilters[HEADQUARTERSKEY] || [])?.find(
          (hq: any) => selectedHq.id === hq.id,
        )
      )
        appliedFilters[HEADQUARTERSKEY] =
          appliedFilters?.[HEADQUARTERSKEY] || [];
      appliedFilters[HEADQUARTERSKEY].push(selectedHq);
    });
  }

  return appliedFilters;
};
export const createDefaultFilterObject = (
  filters: any,
  selectedFilters: any,
) => {
  // discarding this function for now as we need same exact function as createFilterObject
  let appliedFilters: any = {};

  Object.keys(filters).forEach((filterKey: string) => {
    if (filterKey === 'dcrStatus' || filterKey === 'lockUnlockStatus') {
      const res = filters[filterKey]?.filterData?.map((item: any) =>
        Boolean(item?.isChecked),
      );
      appliedFilters[filterKey] = res;
    } else if (
      filterKey === 'stpStatus' ||
      filterKey === 'mtpStatus' ||
      filterKey === 'divisions' ||
      filterKey === 'zones'
    ) {
      const res = filters[filterKey]?.filterData
        ?.filter((item: any) => item?.isChecked)
        ?.map((item: any) => item.id);
      appliedFilters[filterKey] = res?.length ? res : null;
    }
  });

  const regions: any = [];
  const hqs: any = [];

  filters['zones']?.filterData?.forEach((item: any) => {
    item?.regions?.forEach((region: any) => {
      region?.isChecked && regions.push(region.id);

      region?.headquarters?.forEach((hq: any) => {
        hq?.isChecked && hqs.push({...hq, parentId: region.id});
      });
    });
  });

  appliedFilters['regions'] = regions?.length ? regions : null;
  appliedFilters['headQuarters'] = hqs?.length ? hqs : null;

  if (selectedFilters?.[HEADQUARTERSKEY]?.length) {
    selectedFilters?.[HEADQUARTERSKEY]?.forEach((selectedHq: any) => {
      if (
        !(appliedFilters[HEADQUARTERSKEY] || [])?.find(
          (hq: any) => selectedHq.id === hq.id,
        )
      )
        appliedFilters[HEADQUARTERSKEY] =
          appliedFilters?.[HEADQUARTERSKEY] || [];
      appliedFilters[HEADQUARTERSKEY].push(selectedHq);
    });
  }
  return appliedFilters;
};

const FindCurrentIndex = (code: string, appSettingsData: any) => {
  const tempIndex = appSettingsData.findIndex(
    (obj: {code: string}) => obj.code === code,
  );
  return tempIndex;
};
const findGracePeriodForMR = (appSettingsData: any) => {
  const mtpLockedIndex = appSettingsData.findIndex(
    (obj: {code: string}) => obj.code === USER_APP_SETTINGS_KEY.MTPSUBMITLOCKED,
  );
  const mtpSubmitIndex = appSettingsData.findIndex(
    (obj: {code: string}) => obj.code === USER_APP_SETTINGS_KEY.MTP_SUBMIT,
  );
  const gracePeriod =
    new Date(appSettingsData[mtpLockedIndex]?.start).getDate() -
    new Date(appSettingsData[mtpSubmitIndex]?.end).getDate();
  return gracePeriod.toString();
};
const formattedCurrentDay = (appSettingsData: any, code: any) => {
  const MTPsubmitDateMR = new Date(
    appSettingsData[FindCurrentIndex(code, appSettingsData)]?.end,
  );
  return MTPsubmitDateMR.getDate();
};

const formatStartDay = (appSettingsData: any, code: any) => {
  const MTPsubmitDateMR = new Date(
    appSettingsData[FindCurrentIndex(code, appSettingsData)]?.start,
  );
  return MTPsubmitDateMR.getDate();
};

export const shouldShowLockIcon = (
  state: any,
  rowData: IPlanAndMeetStatus,
  appSettingsData: any,
) => {
  const currentDate = new Date();
  const currentFormattedDate = currentDate.getDate();
  let formattedSubmitDate = 0;
  let formattedStartDay = 0;
  const isCurrentMonth = (state?.monthSelected as typeof Boolean)
    ? !state?.monthSelected
    : true;
  const isUserMR = isUserRoleMR(rowData?.roleId);
  const isUserFLM = isUserRoleFLM(rowData?.roleId);
  const isUserSLM = isUserRoleSLM(rowData?.roleId);
  if (isUserMR) {
    const gracePeriod = parseInt(findGracePeriodForMR(appSettingsData));
    formattedSubmitDate = formattedCurrentDay(appSettingsData, 'MTPSUBMIT');
    formattedSubmitDate = formattedSubmitDate + gracePeriod;
  } else if (isUserFLM) {
    formattedSubmitDate = formattedCurrentDay(appSettingsData, 'FLMMTPSUBMIT');
    formattedStartDay = formatStartDay(appSettingsData, 'FLMMTPSUBMIT');
  } else if (isUserSLM) {
    formattedSubmitDate = formattedCurrentDay(appSettingsData, 'SLMMTPSUBMIT');
    formattedStartDay = formatStartDay(appSettingsData, 'SLMMTPSUBMIT');
  }
  if (isCurrentMonth) {
    return isUserMR && rowData.isSTPLocked;
  } else {
    if (formattedSubmitDate <= getDaysInCurrentMonth()) {
      if(isUserFLM || isUserSLM && formattedStartDay > formattedSubmitDate){        
        formattedSubmitDate = getDaysInCurrentMonth() + formattedSubmitDate        
      }
      return rowData?.isMTPLocked && currentFormattedDate > formattedSubmitDate;
    } else {
      return false;
    }
  }
};

export const attachHierarchyToPlanAndMeetItem = (
  planAndMeetList: any,
  hierarchyList: any,
  staffPositionId: number,
  isInnerChild?: boolean,
) => {
  let updatedList = JSON.parse(
    JSON.stringify(planAndMeetList ? planAndMeetList : []),
  );

  if (!isInnerChild) {
    updatedList = updatedList?.map((item: any) => {
      if (item?.staffPositionId === staffPositionId) {
        return {...item, hierarchyList};
      }
      return item;
    });
  } else {
    updatedList = findParent(planAndMeetList, staffPositionId, hierarchyList);
  }

  return updatedList;
};

const findParent = (list: any, staffPositionId: number, hierarchyList: any) => {
  let updatedList = JSON.parse(JSON.stringify(list ? list : []));
  updatedList = updatedList?.map((item: any) => {
    if (item?.hierarchyList?.length) {
      let parent = item?.hierarchyList?.find(
        (child: any) => child?.staffPositionId === staffPositionId,
      );
      if (parent) {
        item['hierarchyList'] = item?.hierarchyList?.map((child: any) => {
          if (child?.staffPositionId === staffPositionId) {
            return {...child, hierarchyList};
          }
          return child;
        });
      } else {
        item['hierarchyList'] = findParent(
          item?.hierarchyList,
          staffPositionId,
          hierarchyList,
        );
      }
    }
    return item;
  });
  return updatedList;
};

export const sortFilters = (filters: any) => {
  const withCount =
    filters
      ?.filter((item: any) => item?.count)
      ?.sort((prev: any, next: any) => {
        return prev?.value?.localeCompare(next?.value);
      }) || [];
  const withoutCount =
    filters
      ?.filter((item: any) => !item?.count)
      ?.sort((prev: any, next: any) => {
        return prev?.value?.localeCompare(next?.value);
      }) || [];
  const sortedByCount = withCount.concat(withoutCount);
  const sortedByChecked = sortedByCount?.sort((prev: any, next: any) => {
    if (prev?.isChecked === next?.isChecked) {
      return 0;
    }
    return prev?.isChecked ? -1 : 1;
  });

  return sortedByChecked;
};

export const sortRecommendations = (recommendations: any) => {
  const sorted = recommendations?.sort((prev: any, next: any) => {
    const prevRole = ROLES_RANK[prev?.givenBy?.roleId as keyof object];
    const nextRole = ROLES_RANK[next?.givenBy?.roleId as keyof object];
    return prevRole - nextRole;
  });
  return sorted;
};

export const createFilterObjectFromPreferredFilters = (
  preferredFilters: any,
) => {
  let appliedFilters = JSON.parse(
    JSON.stringify(preferredFilters ? preferredFilters : {}),
  );

  appliedFilters[MTPSTATUSKEY] = appliedFilters[MTPSTATUSKEY]?.length
    ? appliedFilters[MTPSTATUSKEY]?.map((item: any) => item.id)
    : null;
  return appliedFilters;
};

export const attachMTPObjectToFilters = (filters: any, filterState: any) => {
  let updatedFilters = JSON.parse(JSON.stringify(filters ? filters : {}));

  updatedFilters[MTPSTATUSKEY] = updatedFilters?.[MTPSTATUSKEY]?.map(
    (mtpStatusId: any) =>
      filterState.mtpStatus?.filterData?.find(
        (item: any) => item?.id === mtpStatusId,
      ),
  );

  return updatedFilters;
};

export const selectDeselectHierarchyItems = (
  selectAll: boolean,
  preselectedRows: Array<any>,
  response: any,
) => {
  if (selectAll) {
    const newRows = response?.filter((row:any) => 
      ![...preselectedRows]?.some((item: any) => row.staffPositionId === item.staffPositionId)
    )
    return [...preselectedRows, ...newRows];
  } else {
    return preselectedRows?.filter(row => 
      !response?.some((item: any) => row.staffPositionId === item.staffPositionId)
    )
  }
};

export const isFilterEmpty = (filters: any) => {
  return Object.values(filters).every(
    (x: any) => x === null || x?.every((y: any) => y === false),
  );
};
