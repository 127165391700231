import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IGspScheduleListItem {
  divisionId: number;
  divisionName: string;
  isActive: boolean;
  unlockedEndDate: string;
  unlockedStartDate: string;
}
export interface IGspScheduleInitialState {
  loader: boolean;
  searchText: string;
  gridOptions: any;
  totalRows: number;
  gspScheduleList: Array<any>;
  cloneGspScheduleList: Array<any>;
  originalScheduleList: Array<any>;
  activeFinancialYear: Number;
  allGspYears: Array<any>;
  editableField: any;
  initiateUpcomingYear: any;
  modifiedSchedule: Array<any>;
  saveConfigurationResponse: any;
}

export const gspScheduleInitialState: IGspScheduleInitialState = {
  loader: false,
  searchText: '',
  gridOptions: {},
  totalRows: 0,
  gspScheduleList: [],
  cloneGspScheduleList: [],
  originalScheduleList: [],
  activeFinancialYear: 0,
  allGspYears: [],
  editableField: [],
  initiateUpcomingYear: null,
  modifiedSchedule: [],
  saveConfigurationResponse: null,
};

export const fetchGspScheduleListCreator = createAction<any>(
  'GSP_SCHEDULE_HANDLER/FETCH_GSP_SCHEDULE_LIST',
);
export const fetchGspScheduleListCreatorTypeName =
fetchGspScheduleListCreator({}).type;

export const validatePrerequisiteCreator = createAction<any>(
  'GSP_SCHEDULE_HANDLER/VALIDATE_PREREQUISITE',
);

export const validatePrerequisiteCreatorTypeName =
validatePrerequisiteCreator({}).type;

export const saveConfigurationCreator = createAction<any>(
  'GSP_SCHEDULE_HANDLER/SAVE_CONFIGURATION',
);

export const saveConfigurationCreatorTypeName =
saveConfigurationCreator({}).type;

const gspScheduleHandler = createSlice({
  name: 'GSP_SCHEDULE_HANDLER',
  initialState: gspScheduleInitialState,
  reducers: {
    setGspScheduleList: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        gspScheduleList: payload,
      };
    },
    setCloneGspScheduleList: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        cloneGspScheduleList: payload,
      };
    },
    setOriginalGspScheduleList: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        originalScheduleList: payload,
      };
    },
    setGspScheduleLoader: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        loader: payload,
      };
    },
    setGspScheduleSearchText: (prevState, action) => {
      return {
        ...prevState,
        searchText: action.payload,
      };
    },
    setGspScheduleGridOptions: (prevState, action) => {
      return {
        ...prevState,
        gridOptions: action.payload,
      };
    },
    setTotalRowsInGrid: (prevState, action) => {
      return {
        ...prevState,
        totalRows: action.payload,
      };
    },
    setActiveFinancialYear: (prevState, action) => {
      return {
        ...prevState,
        activeFinancialYear: action.payload,
      };
    },
    setAllGspYears: (prevState, action) => {
      return {
        ...prevState,
        allGspYears: action.payload,
      };
    },
    resetGspScheduleState: () => {
      return {
        ...gspScheduleInitialState,
      };``
    },
    setInitiateUpcomingYear: (prevState, action) => {
      return {
        ...prevState,
        initiateUpcomingYear: action.payload,
      };
    },
    setModifiedSchedule: (prevState, action) => {
      return {
        ...prevState,
        modifiedSchedule: action.payload,
      };
    },
    setSaveConfigResponse: (prevState, action) => {
      return {
        ...prevState,
        saveConfigurationResponse: action.payload,
      };
    },
    setEditableField: (prevState, action) => {
      return {
        ...prevState,
        editableField: action.payload,
      };
    },
  },
});

export const gspScheduleStateActions = gspScheduleHandler.actions;
export const gspScheduleStateReducer = gspScheduleHandler.reducer;
