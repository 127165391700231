import {createSlice, createAction} from '@reduxjs/toolkit';

export const appState = {
  userInfo: {},
  isEditAccessAvailable: false,
  appLoading: false,
  userInfoError: false,
  userInfoErrorMessage: '',
  showCannotAccessModal: false,
  showCannotAccessModalLoading: false,
  cannotAccessModalUsers: [],
  notifications: [],
  appSettings: [],
};

export const fetchUserInfoCreator = createAction(
  'ROUTE_HANDLER/FETCH_USER_INFO',
);
export const fetchAppSettingsCreator = createAction(
  'ROUTE_HANDLER/FETCH_USER_INFO',
);
export const fetchUserInfoCreatorTypeName = fetchUserInfoCreator().type;
export const fetchUserAppSettingsCreatorTypeName =
  fetchAppSettingsCreator().type;

export const fetchCannotAccessModalUsersCreator = createAction(
  'ROUTE_HANDLER/FETCH_CANNOT_ACCESS_MODAL_USERS',
);
export const fetchCannotAccessModalUsersCreatorTypeName =
  fetchCannotAccessModalUsersCreator().type;

export const fetchNotificationsCreator = createAction(
  'ROUTE_HANDLER/FETCH_NOTIFICATION',
);
export const fetchNotificationsCreatorTypeName =
  fetchNotificationsCreator().type;

export const markNotificationReadCreator = createAction<any>(
  'MARK_NOTIFICATION_READ',
);
export const markNotificationTypeName = markNotificationReadCreator({}).type;

export const appSlice = createSlice({
  name: 'app/appSlice',
  initialState: appState,
  reducers: {
    setUserInfo: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        userInfo: payload,
      };
    },
    setAppLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        appLoading: payload,
      };
    },
    setUserInfoError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        userInfoError: payload,
      };
    },
    setUserInfoErrorMessage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        userInfoErrorMessage: payload,
      };
    },
    clearError: prevState => {
      return {
        ...prevState,
        userInfoError: false,
        userInfoErrorMessage: '',
      };
    },
    setShowCannotAccessModal: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        showCannotAccessModal: payload,
      };
    },
    setShowCannotAccessModalLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        showCannotAccessModalLoading: payload,
      };
    },
    setCannotAccessModalUsers: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        cannotAccessModalUsers: payload,
      };
    },
    setIsEditAccessAvailable: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isEditAccessAvailable: payload,
      };
    },
    setNotifications: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        notifications: payload,
      };
    },
    updateNotificationReadStatus: (prevState: any, action) => {
      const {payload} = action;
      return {
        ...prevState,
        notifications: prevState.notifications.map(
          (notification: {id: any}) => {
            if (notification?.id === payload) {
              return {...notification, isRead: true};
            }
            return notification;
          },
        ),
      };
    },
    setAppSettings: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        appSettings: payload,
      };
    },
  },
});

export const appSliceReducer = appSlice.reducer;
export const appSliceActions = appSlice.actions;
