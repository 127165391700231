import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en-in';
import {DEFAULT_DATE_FORMAT, CALENDAR_DATE_FORMAT} from '@app/common/constants';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);
dayjs.extend(localeData);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

/**
 * Utility file to handle Date/Time method
 */

const DEFAULT_FORMAT = DEFAULT_DATE_FORMAT;

/**
 * @param {Date} selectedDate  the selecte date to comapred
 * @param {Date} date Date with which to compare
 * @param {String} format  format of dates to compare
 * @returns
 */
export const isSameDate = (
  selectedDate: any,
  date = new Date(),
  format = DEFAULT_FORMAT,
) => {
  return dayjs(selectedDate).format(format) === dayjs(date).format(format);
};

/**
 * @param {boolean} short if true, returns short month names, else full month names
 * @returns list of months in the year
 */
export const getMonthList = (short: any) => {
  return short ? dayjs.monthsShort() : dayjs.months();
};

/**
 * return  Date in provided format , can be used to get month only
 * Ref :https://day.js.org/docs/en/display/format
 * @param {Object} param
 * @returns formatted date by default it will  return current date
 */
export const getFormatDate = (param: any) => {
  const {date = new Date(), format = DEFAULT_FORMAT} = param || {};
  return dayjs(date).format(format);
};
/**
 * return  Date in provided format , can be used to get month only
 * Ref :https://day.js.org/docs/en/display/format
 * @param {Object} param
 * @returns formatted date by default it will  return current date
 */
export const getTillUnlockDate = (date: any, isLocked: boolean) => {
  if (date && !isLocked) {
    const format = CALENDAR_DATE_FORMAT;
    return dayjs(date).format(format);
  } else return '';
};

export const getNextMonth = () => {
  return dayjs().add(1, 'month').format('MMMM');
};
export const getMonthDiff = (current: any, previous: any) => {
  return dayjs(current).diff(dayjs(previous), 'month');
};

export const getDaysLeftInMonth = () => {
  return dayjs().endOf('month').diff(dayjs(), 'day');
};
/**
 *
 * @param {Object} accept month , year and date
 * @returns date from month & year in DEFAULT_DATE_FORMAT
 */
export const getDateFromMonthYear = ({month, year, date = '01'}: any) => {
  return `${year}-${String(month).padStart(2, '0')}-${date}`;
};

export const getLocalTimeZone = (date: any) => {
  const newDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60 * 1000,
  );
  const timeInHours = newDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  let dateString =
    newDate.getUTCDate() +
    '/' +
    (newDate.getMonth() + 1) +
    '/' +
    newDate.getFullYear();
  dateString = dateString + ' ' + tConvert(timeInHours);
  return dateString;
};

export const getLocalTimeZoneTime = (d: any) => {
  const date = new Date(d);
  const newDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60 * 1000,
  );
  const timeInHours = newDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return tConvert(timeInHours);
};

export const getLocalTimeZoneDate = (d: any) => {
  const date = new Date(d);
  const newDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60 * 1000,
  );

  return newDate;
};

export function tConvert(time: any) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[3] = ' ';
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
}

/**
 * @param {String} inputDate date in utc format
 * @param {String} format expected format of date output
 * @returns date formatted to local time
 */
export const returnUTCtoLocal = (inputDate: any, format: any) => {
  const date = inputDate || dayjs.utc().format();
  const localDate = dayjs.utc(date).local().format();
  const formattedDate = getFormatDate({
    date: localDate,
    format: format || 'D MMM YYYY',
  });
  return formattedDate === 'Invalid Date' ? '-' : formattedDate; // TO DO : Temp fix for web
};

/**
 * Check if a date is after a particular date or note
 *
 * @param {Date} date - Date
 * @param {Date} dateToCompare - Date to compare with
 * @return {Boolean} Is After
 */
export const isAfter = (date: any, dateToCompare: any) => {
  return dayjs(date).isAfter(dateToCompare);
};

/**
 * Check if a date is before a particular date or note
 *
 * @param {Date} date - Date
 * @param {Date} dateToCompare - Date to compare with
 * @return {Boolean} Is After
 */
export const isBefore = (dateToCompare: any) => {
  const date = new Date();

  // add a day
  date.setDate(date.getDate() - 1);
  return dayjs(date).isBefore(dateToCompare);
};

/**
 * Get start of month/day/year based upon unit for a date
 *
 * @param {Date} date
 * @param {String} unit
 * @return {dayjs.Dayjs} Start date
 */
export const startOf = (date: any, unit: any) => {
  return dayjs(date).startOf(unit);
};

/**
 *
 * @param {Date} date
 * @returns Date as an object with day , month & year value
 */
export const getDateIntoObject = (date: any) => {
  return {
    day: dayjs(date).get('date'),
    month: dayjs(date).get('month') + 1,
    year: dayjs(date).get('year'),
  };
};

export const addDays = (date: any, count: any) => dayjs(date).add(count, 'day');

export const endOfDate = (date: any, unit: any) =>
  dayjs(date).endOf(unit).toDate();
export const startOfDate = (date: any, unit: any) =>
  dayjs(date).startOf(unit).toDate();

/** check if date if of not of next month  */
export const isNextMonth = (month: any) => {
  const nextMonth = ((new Date().getMonth() + 1) % 12) + 1;
  return month === nextMonth;
};

// returns parsed date in provided format
export const getParsedDate = (dateToParse: any, format = 'D') => {
  return parseInt(getFormatDate({date: dateToParse, format: format}), 10);
};

/** check if date is of 1st Jan 1990 */
export const isFirstJanDate = (date: any) => {
  return (
    dayjs(date).format(DEFAULT_FORMAT) ===
    dayjs(new Date('1990-01-01T00:00:00')).format(DEFAULT_FORMAT)
  );
};

// return no of days corresponding to month & year
export const getDaysInMonth = (month: any, year: any, date = '01') => {
  return dayjs(`${year}-${month}-${date}`).daysInMonth();
};

/**
 * Check if same month is selected
 * @param {Object} monthFound
 * @param {Object} monthSelected
 * @returns Boolean
 */
export const isSameMonthSelected = (monthFound: any, monthSelected: any) => {
  return (
    monthFound?.month === monthSelected?.month &&
    monthFound?.year === monthSelected?.year
  );
};

export const createCalendarFormat = (
  dates: any,
  layoutData: any,
  joiningDate = null,
) => {
  const currentDay = getFormatDate({format: 'D'});
  const currentMon = getFormatDate({format: 'MM'});
  const cuurentYear = getFormatDate({format: 'YYYY'});
  const list: any = {};
  dates.forEach((date: any) => {
    let {day, month, year} = date;
    const dateFormat = getFormatDate({
      date: `${year}-${month}-${day}`,
      format: DEFAULT_DATE_FORMAT,
    });
    if (
      (year < Number(cuurentYear) ||
        (month < Number(currentMon) && year === Number(cuurentYear)) ||
        (day <= Number(currentDay) &&
          month === Number(currentMon) &&
          year === Number(cuurentYear))) &&
      (joiningDate ? joiningDate <= dateFormat : true)
    ) {
      month = month / 10 < 1 ? '0' + month : month;
      day = day / 10 < 1 ? '0' + day : day;
      const dateString = `${year}-${month}-${day}`;
      list[dateString] = layoutData;
    }
  });
  return list;
};

export const getDateTimeMethod = () => {
  const currentdate = new Date();
  return `Last Sync: ${currentdate.getDate()}/${currentdate.getMonth()}/${currentdate.getFullYear()} @ ${currentdate.getHours()} :${currentdate.getMinutes()} :${currentdate.getSeconds()}:: ${currentdate.getMilliseconds()} `;
};
export const getTodayDate = () => {
  return dayjs().get('date');
};
export const getTimeInFormat = (date: string) => {
  const dateFormat = new Date(date);
  const timeInHours = dateFormat.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  return tConvert(timeInHours);
};
export const getDaysInCurrentMonth = () => {
  return dayjs().daysInMonth();
};
export const getCurrentFinancialYear = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  if (month >= 4) {
    return year + 1;
  } else {
    return year;
  }
};
