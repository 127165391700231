import {API_PATH, HTTP_OK} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {
  gspScheduleStateActions,
  fetchGspScheduleListCreatorTypeName,
  validatePrerequisiteCreatorTypeName,
  saveConfigurationCreatorTypeName,
} from './slice';

/**
 * Fetch gsp schedule List
 */
export function* fetchGspScheduleListWatcher() {
  yield takeLatest(
    fetchGspScheduleListCreatorTypeName,
    fetchGspScheduleListWorker,
  );
}

/**
 * Validate pre-requisite conditions for upcoming year gsp initiation
 */
 export function* validatePrerequisitesWatcher() {
  yield takeLatest(
    validatePrerequisiteCreatorTypeName,
    validatePrerequisitesWorker,
  );
}

/**
 * Save gsp schedule changes
 */
 export function* saveConfigurationWatcher() {
  yield takeLatest(
    saveConfigurationCreatorTypeName,
    saveConfigurationWorker,
  );
}

export function* fetchGspScheduleListWorker(action: any): any {
  try {
    const obj = action?.payload;
    const url = API_PATH.gspSchedule.gspConfigurationList;
    const response = yield call(NetworkService.get, url, obj, {});
    if (response?.status === HTTP_OK) {
      yield put(
        gspScheduleStateActions.setGspScheduleList(
          response?.data?.gspConfiguration,
        ),
      );
      yield put(
        gspScheduleStateActions.setCloneGspScheduleList(
          response?.data?.gspConfiguration,
        ),
      );
      yield put(
        gspScheduleStateActions.setOriginalGspScheduleList(
          response?.data?.gspConfiguration,
        ),
      );
      yield put(
        gspScheduleStateActions.setTotalRowsInGrid(
          response?.data?.gspConfiguration?.length,
        ),
      );
      if (Object.keys(obj).length === 0) {
        yield put(
          gspScheduleStateActions.setActiveFinancialYear(
            response?.data?.activeFinancialYear,
          ),
        );
        yield put(
          gspScheduleStateActions.setAllGspYears(
            response?.data?.gspFinancialYear || [],
          ),
        );
      }
    }
  } catch (error) {}
}

export function* validatePrerequisitesWorker(action: any): any {
  try {
    const obj = action?.payload;
    const url = API_PATH.gspSchedule.validatePrerequisites+ obj;
    const response = yield call(NetworkService.get, url, {}, {});

    if (response?.status === HTTP_OK) {
      yield put(
       gspScheduleStateActions.setInitiateUpcomingYear(response?.data)
      );
    }
  } catch (error) {}
}

export function* saveConfigurationWorker(action:any): any {
  try {
    
    const {activeFY, gspConfig} = action.payload;
    const url = API_PATH.gspSchedule.saveconfiguration + activeFY;
    const response = yield call(NetworkService.post, url, gspConfig);

    if (response?.status === HTTP_OK) {
      yield put(
       gspScheduleStateActions.setSaveConfigResponse(response?.data)
      );
    }

  } catch (error) {}
}
