import {createSelector} from '@reduxjs/toolkit';

const getGspScheduleState = (state: any) => state.gspScheduleMaster;

const getGspScheduleListSelector = createSelector(
  [getGspScheduleState],
  data => data?.gspScheduleList,
);

const getCloneGspScheduleListSelector = createSelector(
  [getGspScheduleState],
  data => data?.cloneGspScheduleList,
);

const getOriginalGspScheduleListSelector = createSelector(
  [getGspScheduleState],
  data => data?.originalScheduleList,
);
const getTotalRowsInGridSelector = createSelector(
  [getGspScheduleState],
  data => data?.totalRows,
);

const getGspScheduleSearchTextSelector = createSelector(
  [getGspScheduleState],
  data => data?.searchText,
);

const getGspScheduleGridOptionsSelector = createSelector(
  [getGspScheduleState],
  data => data?.gridOptions,
);
const getGspYearsSelector = createSelector(
  [getGspScheduleState],
  data => data?.allGspYears,
);
const getActiveFinancialYearSelector = createSelector(
  [getGspScheduleState],
  data => data?.activeFinancialYear,
);

const initiateUpcomingYearSelector = createSelector(
  [getGspScheduleState], 
  data => data?.initiateUpcomingYear,
)

const getModifiedScheduleSelector = createSelector(
  [getGspScheduleState],
  data => data?.modifiedSchedule
)

const getSaveConfigurationResponse = createSelector(
  [getGspScheduleState], 
  data => data?.saveConfigurationResponse,
)

const getEditableFieldSelector = createSelector(
  [getGspScheduleState], 
  data => data?.editableField,
)

export const gspScheduleSelector = {
  getGspScheduleSearchText: () => getGspScheduleSearchTextSelector,
  getGspScheduleGridOptions: () => getGspScheduleGridOptionsSelector,
  getCloneGspScheduleList: () => getCloneGspScheduleListSelector,
  getOriginalGspScheduleList: () => getOriginalGspScheduleListSelector,
  getTotalRowsInGrid: () => getTotalRowsInGridSelector,
  getGspScheduleList: () => getGspScheduleListSelector,
  getGspYears:() => getGspYearsSelector,
  getActiveFinancialYear: () => getActiveFinancialYearSelector,
  getInitiateUpcomingYear: () => initiateUpcomingYearSelector,
  getModifiedSchedule: () => getModifiedScheduleSelector,
  getSaveConfigurationResponse: () => getSaveConfigurationResponse,
  getEditableField: () => getEditableFieldSelector,
};
